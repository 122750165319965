/* @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css"); */

/* 텍스트 선택 */
::selection {
  background: #E4C8DB;
  color: #8B3E66;
}
  
.video-responsive {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 비율 */
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  
  .content-container {
    margin: 20px;
  }
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
  }
  .dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .next-item {
    border-radius: 30px;
    border: 1px solid var(--gray-20, #E6E9ED);
    background: #FFF;
    display: inline-flex;
    width: 400px;
    height: 250px;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: center;
    overflow: hidden;
    position: fixed; /* 화면에 고정된 위치에 배치 */
    bottom: 20px; /* 페이지 하단에서 20px 떨어진 위치 */
    right: 20px; /* 페이지 오른쪽에서 20px 떨어진 위치 */
    opacity: 0;
    cursor: pointer;
    transform: translateY(500px);
    transition: box-shadow 0.5s, transform 0.8s;
  }
  
  .next-item:hover{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    
  }
  .next-item-text {
    position: absolute;
    top: 134px;
    /* bottom: 8px; */
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0px;
    margin: 10px;
    width: auto;
    flex-shrink: 0;
  }

  .next-sign {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    z-index: 3;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px); 
    transition: .3s ease-in-out;
  }

  .next-sign-hover {
    top: 16px;
    right: 16px;
    width: 14px;
    height: 14px;
  }

  .next-text {
    color: #767D85;
    font-size: 14px;
    font-weight: 600;
  }
  .next-project-title {
    color: var(--gray-100, #131517);
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    line-height: 135%;
  }

  .next-item-thumbnail {
    width: 400px;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    z-index: 1;
  }


  .next-item-thumbnail img {
    width: 100%;
    background-color: #F2F3F6;
    /* height: 100%; */
    /* object-fit: cover; 이미지의 짧은 변이 컨테이너를 꽉 채우고 비율을 유지 */
    object-position: center; /* 이미지를 중앙에 배치 */
  }

  .next-item-thumbnail-hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    margin: 8px;
    width: 366px;
    height:120px;
    border-radius: 24px;
 }

  .next-item.scrolled-to-bottom {
    transform: translateY(0); /* 스크롤 후 버튼을 화면에 표시 */
    opacity: 1; /* 버튼을 보이게 설정 */
  }

  .activitycard {
    border-radius: 30px;
    border: 1px solid var(--gray-20, #E6E9ED);
    background: #FFF;
    display: inline-flex;
    width: 400px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  }

  .activitycard:hover{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .activitycard-thumbnail {
    width: 400px;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    z-index: 1;
  }

  .activitycard-thumbnail img {
    width: 100%;
    background-color: #F2F3F6;
    /* height: 100%; */
    /* object-fit: cover; 이미지의 짧은 변이 컨테이너를 꽉 채우고 비율을 유지 */
    object-position: center; /* 이미지를 중앙에 배치 */
  }

  .activitycard-thumbnail-hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    margin: 8px;
    width: 366px;
    height:120px;
    border-radius: 24px;
 }
  .BlackButton {
    position: relative;
    background: #EB5757;
    color: white;
    overflow: hidden;
    z-index: 1;
    transition: padding 0.3s, gap 0.3s;
    display: inline-flex;
    padding: 20px ;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: transparent;
    background: #EB5757;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }

  .BlackButton::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #EB5757 0%, #6A27F9 100%);
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 0;
  }

  .BlackButton:hover::before {
    opacity: 1;
  }

  .BlackButton span,
  .BlackButton svg {
    position: relative;
    z-index: 1;
    
  }

  .BlackButton:hover {
    gap: 14px;
    padding: 20px 16px 20px 20px ;
    
  }

  .floatingButton {
    position: fixed; /* 화면에 고정된 위치에 배치 */
    bottom: 20px; /* 페이지 하단에서 20px 떨어진 위치 */
    left: 20px; /* 페이지 오른쪽에서 20px 떨어진 위치 */
    display: inline-flex;
    padding: 20px ;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--gray-20, #E6E9ED);
    background: var(--white, #FFF);
    color: var(--gray-100, #131517);
    transition: background-color 0.5s, box-shadow 0.5s, gap 0.3s, padding 0.3s, transform 0.5s;/* 부드러운 색상 변화 */
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    transform: translateY(100px);
    opacity: 0; /* 기본 상태에서 버튼을 숨기기 */

  }
  
  .back-button svg {
    margin-right: 8px; /* 텍스트와 아이콘 사이의 간격 */
    stroke: currentColor; /* 아이콘의 색상을 버튼의 텍스트 색상으로 설정 */
    fill: currentColor; /* 아이콘의 색상을 버튼의 텍스트 색상으로 설정 (이 경우 stroke와 fill 모두 설정) */
  }

  .floatingButton.show {
    transform: translateY(0); /* 스크롤 후 버튼을 화면에 표시 */
    opacity: 1; /* 버튼을 보이게 설정 */
  }

  .floatingButton:hover {
    gap: 16px;
    padding: 20px 20px 20px 14px  ;
    border-color: transparent;
    background-color: #131517; 
    color: #fff;
  }

  .floatingButton.scrolled-to-bottom {
    border-color: transparent;
    background-color: #131517; 
    color: #fff;
  }

  .menu {
    display: flex;
    flex-direction: row;
    text-align: right;
    gap: 28px;
  }
  .nav {
    display: flex;
    flex-direction: row;
    padding : 20px 48px;
    justify-content: space-between;
    align-items: center; 
    position: sticky;
    top: 0;
    z-index: 3;
    /* background: linear-gradient(180deg, #FFF 0% , rgba(255, 255, 255, 0.00) 100%); */
    backdrop-filter: blur(4px);
    transition: transform 0.3s ease;
  }

  .Menu {
    color: #222429;
    font-size: 15px;
    font-weight: 500;
    text-decoration-line: none;
    text-decoration: none;
  }

  .Menu:hover {
    color: #222429;
    font-size: 15px;
    font-weight: 700;
    text-decoration-line: none;
    text-decoration: none;
  }


  .defaultMenu {
    color: #222429;
    font-size: 15px;
    font-weight: 500;
    text-decoration-line: none;
    text-decoration: none;
  }

  .slectedMenu {
    color: #222429;
    font-size: 15px;
    font-weight: 700;
    text-decoration-line: none;
    text-decoration: none;
  }

  .defaultChip {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--gray-20, #E6E9ED);
    color: var(--gray-100, #131517);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #FFF;

  }
  .defaultChip:hover {
    background-color: #E6E9ED;
  }

  .selectedChip {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--gray-100, #131517);
    /* color: var(--gray-100, #131517); */
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;   
  }
  
  .filter {
    display: flex;
    max-width: 1400px;
    align-items: flex-start;
    gap: 8px;
    padding : 12px 54px;
    position: sticky;
    top: 0;
    z-index: 3;
    overflow: scroll;
    overflow: auto;
    white-space: nowrap;
    background-color: #FFF;
    margin: auto;
  }

  .filter::-webkit-scrollbar{
    display: none; 
  }

  .companyLogo {
    width: 80px;
    height: 80px;       
    flex-shrink: 0;
  }

  .logo {
    width: 80px;
    height: auto;       
    flex-shrink: 0;
  }

  .pageTitle {
    font-size: 72px;
    font-family:Shrikhand;
    padding:48px 40px 48px 40px;
    color: #131517;
    max-width: 1400px;
    margin : auto;
  }

  .workContentslist {
    margin : auto;
    padding: 48px 48px 80px 48px;
    gap: 100px;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    justify-content: center;
    align-content: center;
  }


  .contents {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 22px;
    align-self: stretch;
    cursor: pointer;
  }

  .highlight-contents {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: flex-start;
    gap: 22px;
    align-self: stretch;
    cursor: pointer;
  }

  .project {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
  }

  .project-next {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
  }

  .projectThumbnail {
    width: 100%;
    background-color: #e5e5e5;
    flex-shrink: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }

  .projectThumbnailHover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
 }

  .projectContents { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .projectHighlightContents { 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  }

  .projectSubtitle {
    color: #131517;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .projectTitle {
    color: #131517;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .projectTitleHover {
    text-underline-offset: 3.5px;
    text-decoration: underline 2.5px;

  }
  
  .projectDescription {
    color: #51575E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    
  }

  .projectPeriod {
    color: #51575E;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .projectContentsAll {
    position: relative;
    flex: 1;
  }

  .textGradient {
    background: linear-gradient(90deg, #EB5757 0%, #6A27F9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
  }

  .projectNewBadge {
    display: inline-flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(90deg, #EB5757 0%, #6A27F9 100%);
    color: var(--white, #FFF);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 2;
    position: absolute;
    top: -8px;
    left: 14px;
  }

  .aboutContents {
    padding: 120px 48px;
    gap: 28px;
    flex-direction: column;
    display: flex;
    margin: auto;
    max-width: 1400px;
  }

  .aboutExperienceList {
    gap : 60px;
    padding-top : 100px;
    padding-bottom : 100px;
    flex-direction: row;
    display: flex;
  }


  .aboutIntroCircle1 {
    width: 893px;
    height: 222px;
    transform: rotate(-7.025deg);
    flex-shrink: 0;
    border-radius: 893px;
    border: 1px dashed #CED3D8;
  }
  
  .aboutIntroSection {
    padding-bottom: 180px;
    padding-top: 120px;
    padding-right: 48px;
    padding-left: 48px;
    gap: 40px;
    flex-direction: column;
    display: flex;
    
  }
  .aboutIntro {
    color: #131517;
    font-family:  
      /* PridiMedium, */
      Shrikhand, Pacifico, -apple-system, BlinkMacSystemFont, sans-serif ;
    font-size :92px;
    line-height: 140%;
    /* font-weight: 600; */
    /* letter-spacing: -1.0px; */
    max-width: 1400px;
    z-index: 1;
    
  }

  .aboutIntroDescription {
    color: #222429;
    font-size : 24px;
    font-style: normal;
    line-height: 161.8%;
    font-weight: 300;
    max-width: 1000px;
  }
  
  .aboutTitle {
    color: #131517;
    font-family: Shrikhand, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size :60px;
    font-style : normal;
    line-height: 151.8%;
    /* font-weight: 700; */
    

  }

  .aboutDescription {
    color: #3A3E44;
    /* letter-spacing: -0.5px; */
    font-size: 22px;
    font-style: normal;
    font-weight: 350;
    line-height: 151.8%;
    margin-bottom : 100px;
  }

  .divider1 {
    color : #E6E9ED;
    stroke-width: 1px;
    height: 0px;
    align-self: stretch;
  }

  .dividerWave {
    height: 100%;
    width:100%;
    background-image: url( "./image/wavedivider.png" );
    background-repeat: repeat;
    overflow:"hidden"
  }

  .aboutContactList {
    flex-direction: column;
    display: flex;
    gap : 12px;
  }
  
  .aboutContactListItem {
    /* font-family: Montserrat, -apple-system, BlinkMacSystemFont, sans-serif; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    /* letter-spacing: -0.2px; */
  }


  .aboutContactListItem:hover {
    animation-timing-function: ease-in-out;
    animation-name: shake;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    text-underline-offset: 4px;
    text-decoration: underline;

  }

  
  .aboutExperienceListTitle {
    color: #222429;
    flex-direction: column;
    width : 240px;
    display: flex;
    gap: 6px;
    /* font-family:Montserrat; */
  }

  .companyTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 151.8%;
    font-family:Montserrat;
  }
  .location {
      color: #767D85;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 151.8%;
  }
  .experience {
      color: #222429;
      font-size: 18px;
      font-weight: 350;
      line-height: 151.8%;
      flex: 1 0 0;
  } 
  .period{
    color: #131517;
    font-size: 16px; 
    font-weight: 700;
    text-align: right;
    line-height: 151.8%;
    width : 160px;
  }
  

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    flex-flow: column;
  }



  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    offset: 10;
  }


  @keyframes zoomIn2 {
    from {

      opacity: 0;
      transform: scale3d(0.9, 0.9, 0.9);
    }
  
    70% {
      transform: none;
      opacity: 1;
    }
  }
  
  .zoomIn {
    animation-name: zoomIn2;
    animation-duration: 1s;
    offset: 10;
  }


  @keyframes flow {
    0% {
      transform: translate3d(0);
    }
  
    50% {
      transform: translate3d(16px, -12px, 0);
    }
  
    100% {
      transform: translate3d(0);
    }
  }
  
  .flow {
    animation-timing-function: ease-in-out;
    animation-name: flow;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    offset: 10;
    width: 480px;
    aspect-ratio: auto; 
    margin-bottom: 28px;
  }

  @keyframes shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-1.5px, 0, 0);
    }
  
    20%,
    40%,
    60%,
    80% {
      transform: translate3d(1.5px, 0, 0);
    }
  }
  

  @keyframes wave {
    from,
    to {
      transform: translate3d(-122px, 0, 0);
    }
  
    100% {
      transform: translate3d(20px, 0, 0);
    }
  }
  
  .wave {
    animation-name: wave;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    offset: 10;
    animation-timing-function:linear;
  }

  .nurmericIndex {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #F7F8FA;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    background:#3A3E44;
  }


  @media screen and (max-width: 600px) {
   
    .menu {
      gap: 20px;
    }
    .nav {
      padding : 20px 20px;
    }
    .filter {
      padding : 12px 22px;
    }
    .defaultChip {
      display: flex;
      height: 40px;
      padding: 0px 14px;
      font-size: 13px;
    }  
    .selectedChip {
      height: 40px;
      padding: 0px 14px;
      font-size: 13px;
    }
    
    .logo {
      width: 68px;
    }
  
    .pageTitle {
      font-size: 52px;
      padding:40px 20px;
    }
    .workContentslist {
      padding: 40px 20px;
      gap: 60px;
    }
    .contents {
      flex-direction: column;
      gap: 60px;
    }
    .aboutContents {
      padding: 60px 20px;
      gap: 28px;
    }  
    .aboutExperienceList {
      gap : 20px;
      padding-top : 60px;
      padding-bottom : 60px;
      flex-direction: column;
    }
    
    .aboutIntroSection {
      padding-bottom: 60px;
      padding-top: 60px;
      padding-right: 20px;
      padding-left: 20px;
      gap: 40px;
      flex-direction: column;
      display: flex;
      
    }
    .aboutIntro {
      font-size :52px;
      line-height: 120%;
      
    }
  
    .aboutIntroDescription {
      font-size : 18px;
      margin: 0px 20px;
      z-index: 2;
    }
    
    .aboutTitle {
      font-size :40px;
    } 
    .aboutDescription {
      font-size: 16px;
      margin-bottom : 0px;
    }  
    .aboutContactListItem {
      font-weight: auto;
    }
    
    .aboutExperienceListTitle {
      gap: 4px;
    }
    .companyTitle {
      font-size: 20px;
    }
    .location {
        font-size: 14px;
    }
    .experience {
      font-size: 16px;
    } 
    .period{
      font-size: 14px;
      text-align: left;
    } 
    .aboutContactListItem {
      font-size: 15px;
    }
    .projectTitle {
      font-size: 26px;
    }

    .projectDescription {
      font-size: 15px;
    }
    .projectContents {
      gap: 12px;
    }
    .flow {
      max-width: 300px;
      padding: 0 24px;
    }
  }