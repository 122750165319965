.work-page-bottom-padding{
    padding-bottom: 100px;
}

.imageCaption {
    font-size: 16px;
    color: #51575E;
    text-align: center;
}

.image-center {
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-height: 700px;
    max-width: 1600px;
    /* margin: 0px 48px; */
    margin:auto;
    flex-direction: column; 
  }

.horizontal-layout {
    display:flex;
    flex-direction:row;
    gap:24px; 
    margin:auto;
    max-width:1600px;
}

.workImage {
    /* aspect-ratio: auto; */
    width: 100%;
    height: auto;
    object-fit: contain; 
    max-width: 1200px;
    display: flex;
    margin:auto;
    overflow: hidden;
}

.maxheight600 {
    max-height: 600px;
}

.bottomPadding {
    padding:0px 0px 120px 0px;    
}

.workFullImage {
    width: 100%;
    max-width: 1600px;
    object-fit: contain; 
    overflow: hidden;
    display: flex;
    align-self: stretch;
    align-items: center;
    margin: auto;
}
.worksimage-GrayBackgroundColor {
    background-color: #F2F3F6;
}
.worksimage-Shadow {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
}
.worksimage-Border {
    border: 1px solid var(--gray-10, #F4F5F8);
}
.corner-round24 {
    border-radius: 24px;
}

.margin48 {
    padding: 0px 48px;
}

.bottomMargin80 {
    margin: 0px 0px 80px 0px;
}

.bigTitle {
    font-size: 32px;
}
.textsection {
    margin: auto; 
    max-width: 1600px; 
    align-content: center; 
    padding: 120px 48px 60px 48px;
    display: flex; 
    flex-direction: column; 
    gap: 28px;
}

.contentsalign {
    margin: auto; 
    max-width: 1600px; 
    align-content: center; 
    padding: 0px 48px;
    display: flex; 
}

.gap20 {
    display: flex; 
    flex-direction: column; 
    gap: 20px;
}

@media screen and (max-width: 600px){
    .imageCaption {
        font-size: 13px;
    }
}